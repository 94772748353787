<template>
  <CModal class="ActionModel" size="xl" :show.sync="Toggle" :title="$t('Global.Choose') + $t('Product/SinglePage.ShipmentWay')" :centered="true" @update:show="HandleHideModal()">
    <CCard>
      <CCardBody class="p-0">
        <CDataTable
          :loading="Loading"
          id="ProductList"
          :items="List"
          :fields="Field"
          :noItemsView="noItemsView"
          responsive
          hover
        >
          <template #Checkbox-header>
            <div class="text-center">
              <label class="mb-0">
                <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll">
              </label>
            </div>
          </template>
          <template #Checkbox="{item}">
            <td class="text-center">
              <label class="mb-0">
                <input type="checkbox" :id="'Checkbox-List-' + item.ID" :value="item.ID" v-model="ChooseIndex">
              </label>
            </td>
          </template>
          <template #No="{index}">
            <td class="text-center">
              {{ index + 1}}
            </td>
          </template>
          <template #System="{item}">
            <td>
              {{ $t('Logistics.LogisticsSystem.' + item.System) }}
            </td>
          </template>
          <template #Method="{item}">
            <td>
              {{ $t('Logistics.LogisticsMethods.' + item.Method) }}
            </td>
          </template>
        </CDataTable>
      </CCardBody>
    </CCard>
    <template #footer-wrapper>
      <footer class="modal-footer border-top-0">
        <CButton id="ChooseProduct" @click="Choose()" color="success" block>
          {{ $t('Global.Confirm') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ['Toggle'],
  name: "LogisticsListComponent",
  data() {
    return {
      List: [],
      ChooseIndex: [],
      CheckAllValue: false,
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Global.NoResults'),
        noItems: this.$t('Global.NoItems')
      }
    }
  },
  computed: {
    Field () {
      return [
        { key: 'Checkbox', label: '' },
        { key: 'No', label: 'No' },
        { key: 'Name', label: this.$t('Logistics.Name') },
        { key: 'System', label: this.$t('Logistics.System') },
        { key: 'Method', label: this.$t('Logistics.Method') }
      ]
    }
  },
  mounted() {
    this.Init().catch((err) => {
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList()
      ]).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    GetList() {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/system/logistics/list',
        method: 'get'
      }).then(({data}) => {
        this.Loading = false
        this.List = Object.keys(data).map((id) => {
          return {
            ...data[id],
            ID: id,
            System: data[id].System.toUpperCase()
          }
        })
        this.ListObject = data
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseIndex = this.List.map(data => {
          return data.ID
        })
      } else {
        this.CheckAllValue = false
        this.ChooseIndex = []
      }
    },
    Choose() {
      this.$emit('ChooseLogistics', this.List.filter(data => {
        return this.ChooseIndex.includes(data.ID)
      }))
      this.HandleHideModal()
    },
    HandleHideModal () {
      this.$emit('update:Toggle', false)
    }
  }
}
</script>

